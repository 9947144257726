@font-face {
	font-family: Ubuntu-Regular;
	src: url(../fonts/Ubuntu-R.ttf);
}

@font-face {
	font-family: Ubuntu-Medium;
	src: url(../fonts/Ubuntu-M.ttf);
}

@font-face {
	font-family: Ubuntu-Bold;
	src: url(../fonts/Ubuntu-B.ttf);
}

:root {
	--main-color: #424b5a;
	--secondary-color: #a1aeb7;
	--third-color: #255f8e;
	--fourth-color: #f2f5f7;
}

#root {
	position: relative;
	min-height: 100vh;
}

a {
	text-decoration: none !important;
	color: var(--main-color) !important;
}

a:hover {
	text-decoration: underline !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	margin-top: 30px;
	margin-bottom: 10px;
}

body {
	background-color: #fff !important;
}

.container {
	margin: 20px auto !important;
}

.flex {
	display: flex;
}

.flex-row {
	flex-direction: row;
	flex-wrap: wrap;
}

.flex-align-center {
	align-items: center;
}

.flex-justify-space-between {
	justify-content: space-between;
}

.header-wrapper {
	background: var(--third-color);
	transform: skewX(-10deg);
	min-width: 60%;
	margin-left: -10px;
	max-width: 60%;
	display: inline-block;
}

.image-banner .header-wrapper {
	position: absolute;
	top: 0%;
}

.page-header {
	font-family: Open-Sans;
	font-weight: 700;
	font-size: 32px;
	/*color: var(--main-color);*/
	text-align: center;
	margin: 50px 0px 20px;
	border-bottom: none !important;
	color: white;
	/* padding-left: 20px; */
	transform: skewX(10deg);
	padding: 10px 10px 10px 20px;
	text-transform: uppercase;
}

/*.page-header-side {
  font-family: Open Sans;
  font-weight: 700;
  text-align: left;
  margin: 50px 0px 20px;
  border-bottom: none !important;
  color: white;
  transform: skewX(10deg);
  padding: 10px 10px 10px 30px;
  text-transform: uppercase;
}*/

.page-header-side {
	font-family: Open-Sans;
	font-weight: 700;
	font-size: 32px;
	/* color: var(--main-color); */
	text-align: left;
	margin: 0px 20px;
	border-bottom: none !important;
	color: white;
	/* padding-left: 20px; */
	transform: skewX(10deg);
	padding: 10px 10px 10px 30px;
	text-transform: uppercase;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-100 {
	margin-bottom: 100px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-20-mb-10 {
	margin-top: 20px;
	margin-bottom: 10px;
}

.mt-40-mb-20 {
	margin-top: 40px;
	margin-bottom: 20px;
}

.margin-top-20-bottom-10 {
	margin-top: 20px;
	margin-bottom: 10px;
}

.margin-top-40-bottom-20 {
	margin-top: 40px;
	margin-bottom: 20px;
}

.m-t20-b5 {
	margin-top: 20px;
	margin-bottom: 5px;
}

.sub-header-light {
	font-family: Ubuntu-Medium;
	font-size: 24px;
	color: var(--secondary-color);
}

.sub-header {
	font-family: Ubuntu-Medium;
	font-size: 24px;
	font-weight: 600;
	color: var(--main-color);
}

.sub-sub-header {
	font-family: Ubuntu-Medium;
	font-size: 20px;
	font-weight: 500;
	color: var(--main-color);
}

.page-description {
	color: var(--main-color);
	font-size: 16px;
	line-height: 32px;
	font-family: Ubuntu-Regular;
	margin-bottom: 30px;
}

.page-description a {
	color: var(--main-color);
}



/********** Badges ************/
.badge-custom {
	color: white;
	padding: 0.5em 1em;
	font-size: 12px;
}

.badge-sisa {
	background: var(--third-color);
}

/********** Buttons **********/
.btn-custom,
.btn-cart {
	background: var(--third-color);
	border: none;
}

.btn-custom:hover,
.btn-cart:hover {
	background: #1d496d;
}

/********** Cart **********/
.btn-cart .badge {
	background: rgba(255, 255, 255, 0.5);
	color: black;
}

.mb-0 {
	margin-bottom: 0px !important;
}

.cart-header, .cart-row, .cart-footer {
	border: 1px solid rgba(0,0,0,.125);
}

.cart-col {
	display: flex;
	align-items: center;
}

.flex-horizontal-center {
	align-items: center;
    display: flex;
}

/*** DataTables ***/
.fvAeSn {
	font-size: 14px !important;
}

.fhCFWO {
	transition: all ease 0.25s;
}

.fhCFWO:hover {
	cursor: pointer;
	background-color: rgba(0,0,0,.125);
}

.fFBlx {
	font-size: 16px !important;
}

.btn-small {
	padding: 0.25rem 0.5rem !important;
    font-size: .75rem !important;
}

/********** Calendar **********/
.fc-scrollgrid  .fc-scrollgrid-liquid {
	max-width: 1100px;
}

/********** Cart **********/
.cart-loading-overlay {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100vh;
    background: #424b5a73;
    z-index: 1000;
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.cart-loading-overlay a {
	font-weight: 500;
	color: black !important;
}


/********** Membership Component **********/
.membership-status-badge {
	margin-left: 10px;
}

