/********** Nav **********/
.sidenav---sidenav---_2tBP {
	background: var(--third-color) !important;
	height: 100vh;
    position: fixed !important;
}

#main-content {
	margin-left: 64px;
}

.sidenav---sidenav---_2tBP:hover {
	min-width: 240px;
}

.link .sidenav---navtext---1AE_f {
	visibility: hidden;
	opacity: 0;
	-ms-filter: none;
	filter: none;
	transition: opacity 0.2s linear;
	width: 0;
}

.sidenav---sidenav---_2tBP:hover
	> .sidenav---sidenav-nav---3tvij
	> .sidenav---sidenav-navitem---uwIJ-
	> .sidenav---navitem---9uL5T
	> .sidenav---navtext---1AE_f, 
	
	.sidenav---sidenav---_2tBP:hover
	> .sidenav---sidenav-nav---3tvij > .bottom-links > .link
	> .sidenav---sidenav-navitem---uwIJ-
	> .sidenav---navitem---9uL5T
	> .sidenav---navtext---1AE_f  {
	visibility: visible;
	opacity: 1;
	-ms-filter: none;
	filter: none;
	transition: opacity 0.2s linear;
}

.sidenav---sidenav---_2tBP:hover
	> .sidenav---sidenav-nav---3tvij
	> .link
	> .sidenav---sidenav-navitem---uwIJ-
	> .sidenav---navitem---9uL5T
	> .sidenav---navtext---1AE_f {
	visibility: visible;
	opacity: 1;
	-ms-filter: none;
	filter: none;
	transition: opacity 0.2s linear;
}

.link .sidenav---sidenav-navitem---uwIJ- {
	position: relative;
	display: block;
	line-height: 50px;
	height: 50px;
	white-space: nowrap;
	text-decoration: none;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
}

.link .sidenav---navitem---9uL5T {
	position: relative;
	display: block;
	line-height: 50px;
	height: 50px;
	white-space: nowrap;
	text-decoration: none;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
}

.link .sidenav---navicon---3gCRo {
	display: block;
	float: left;
	width: 64px;
	height: 50px;
	margin-right: -6px;
	vertical-align: top;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: transparent;
	opacity: 0.7;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
	filter: alpha(opacity=70);
	line-height: 50px;
	text-align: center;
}

.sidenav---sidenav-navitem---uwIJ-:hover,
.active > .sidenav---sidenav-navitem---uwIJ-, .sidenav---sidenav---_2tBP > .sidenav---sidenav-nav---3tvij > .bottom-links > .link > .sidenav---sidenav-navitem---uwIJ-:hover {
	transition: all 0.3s;
	background: rgba(255, 255, 255, 0.25);
	text-decoration: none;
}

.sidenav---sidenav---_2tBP > .sidenav---sidenav-nav---3tvij > .bottom-links {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.link:hover {
	text-decoration: none !important;
}

.svg-inline--fa {
	font-size: 1.25em !important;
}

.bottom-links-floating, .mobile-only {
	display: none;
}

.mobile-only {
	display: none;
}

.more-nav {
	position: absolute;
    bottom: 55px;
    right: 4px;
    z-index: 1000;
    background: var(--third-color);
}

@media(max-width: 540px) {
	.mobile-only {
		display: block;
	}

	#main-content {
		margin-left: 0px;
		margin-bottom: 100px;
	}

	.mobile-only {
		display: block;
	}

	.non-mobile {
		display: none;
	}

	.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv {
		display: flex;
		flex-direction: row;
		position: fixed !important;
		bottom: 0;
		height: 50px;
		top: unset;
		width: 100%;
	}
	.sidenav---sidenav-nav---3tvij {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
	.sidenav---navtext---1AE_f {
		display: block !important;
		float: none !important;
		text-align: center !important;
		width: auto !important;
		margin-top: 5px !important;
		font-size: 10px !important;
		line-height: 10px !important;
		visibility: visible !important;
		opacity: 1 !important;
	}
	.bottom-links {
		display: none;
	}
	.bottom-links-floating {
		position: absolute;
		display: flex;
		top: 0;
		right: 0;
		z-index: 5000;
	}
	.bottom-links-floating .sidenav---sidenav-navitem---uwIJ- {
		background: var(--main-color);
		border-radius: 50%;
		height: 36px;
		width: 36px;
		margin: 10px
	}
	.bottom-links-floating .link .sidenav---navitem---9uL5T {
		height: 36px;
		width: 36px;
	}
	.sidenav---navitem---9uL5T {
		display: flex !important;
		flex-direction: column !important;
		justify-content: center !important;
		align-items: center !important;
		width: 64px !important;
	}
	.sidenav---navicon---3gCRo {
		width: 100%;
		display: flex !important;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: auto !important;
		margin-right: unset !important;
	}
	.bottom-links-floating .sidenav---navitem---9uL5T, .bottom-links-floating .sidenav---navicon---3gCRo, .bottom-links-floating .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.bottom-links-floating .sidenav---navicon---3gCRo {
		margin-right: 0px;
	}

	.link .sidenav---sidenav-navitem---uwIJ- {
		padding: 1px;
	}
}