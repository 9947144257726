.event-card-header {
	justify-content: space-between;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.event-card-header-row {
	justify-content: space-between;
	display: flex;
	align-items: center;
	flex-direction: row;
}

.event-card-header .inner {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.event-card-header .inner a {
	margin-right: 10px;
}

.event-card-header .inner h6 {
	font-weight: 700;
}

.event-card-header .detail-row {
	width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.event-card-header h6 {
	margin-bottom: 0px;
}

.event-card {
	min-height: 250px;
}

.react-datetime-picker__wrapper {
	border: none !important;
}
.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
	border-color: #dc3545 !important;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
	border-color: #dc3545 !important;
	box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%) !important;
}

.event-edit-btn {
	width: 24px;
    height: 24px;
    display: inline-flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--third-color) !important;
	border: none !important;
}

.event-edit-btn svg {
	margin: 0px !important;
    color: white;
	font-size: 1em !important;
}

.react-date-picker__wrapper {
	border: none !important
}

.custom-input-group {
	display: flex;
	flex-direction: row;
	align-items: center !important;
	margin-bottom: 10px;
}

.custom-input-group label {
	margin-bottom: 0px;
	margin-right: 5px;
}

.custom-input-group input {
	margin-bottom: 0px;
	margin-right: 5px;
}

.dropzone-ui .dz-ui-footer {
	height: auto !important;
}