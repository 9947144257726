.login-card {
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    left: 50%;
    width: 500px;
    height: 500px;
}

.logo-small {
    height: 125px;
}

@media (max-width: 540px) {
    .login-card{
        width: 90%;
    }
}