.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

/*.modal-dialog {
  top: 50% !important;
}

.modal.show .modal-dialog {
  transform: translateY(-75%) !important;
  top: 50% !important;
}*/

.modal .btn-close {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAACZElEQVRIibVVv2tTURT+zrviUoJCCShOETJVh5fcJPiG/gFCF4UMRmkHF1FacVFcBBdxsnUSKRShLhkcHPwDCoqP9yMOhQ4SnKSBghgkSG6Se1ySenPzXmKgnum975zzfe9+9/AO8J+DRg9xHC9rra8auUMp5da/kIRhuAHg/OjdcZwPhUJhDwBOjUBmPgPgodXYkVJuzyC/DWDTxJj548QJhsW7AGoG1BFCuK7rfk0iD4LgIhF9AZAxyHdLpdKt49OYDUKIewC+G9BCv99/U6/XhU3OzA6AHZMcQKvX622YdWMCruv+JKI7JkZEV3K53CNbIIqix0S0bNXe9TzvxxhmNwKJVvW11l65XA4AIAgCl4g+AzhtnGjMmqkCjUbj7GAw2AdwwYAPlFLFbrfLmUwmAHDJyLWUUkv216cKAEAURSvM/N6Ct4Y962MkRNeLxeK7JJ5UASDRKk7oeyulvJnG4aQlgMSpIou8pZRax5SYKpA0VWYkTc1cAicRU+/A9/1FIcQ+gHMpJUeO4ywVCoWjNI5Zd7BlkTP+XjQAZLXWL6ZxzDWmzLxJRARg7Hcw95imWHOglCpms1ndbrcDAJeNXKpViRYJIV5a5H2t9arneb/z+XyXmVcBKCOfatWEQBRFKwBumBgRPRn9hwCgVCo1mPmp1VqLoujaVAHf9xeZ+bVF/qnZbD63G6WUz5h5z8SY+VUcx9lUgQRrOo7jrFWr1YEtQERaa70G4JcBT1h1LJBkDYD7adsMACqVyjcADyy4NuQCYOxkImozs2nF4ax9DABSyu0wDBdgLH0ias/qO7H4A6z0+3+PV1GiAAAAAElFTkSuQmCC);
  background-position: center;
  background-size: cover;
  height: 20px;
  width: 20px;
  padding: 0px;
  background-color: unset;
}