.text-align-right {
	text-align: right;
}

.booking-item-details {
	display: block;
}

.divider {
	height: 2px;
	background: rgba(0, 0, 0, 0.25);
	width: 100%;
}
.booking-details-toggle{
	padding: 1.25rem;
	flex-direction: row;
	align-items: center;
}
.booking-details-toggle:hover {
	cursor: pointer;
}

.booking-item-detail-col {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-left: 15px;
	padding-right: 15px;
}

.booking-item-detail-col a {
	word-break: break-all;
}

.booking-event-item {
	display: flex;
	flex-direction: column;
}

.booking-event-item-col {
	display: block;
    height: auto;
    flex-basis: unset;
    padding-right: 15px;
    padding-left: 15px;
}