.kebab-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.kebab-menu span{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000;
    margin-bottom: 5px;
    transition: .3s;
}

.kebab-menu:hover span {
    background: rgb(146, 146, 146);
    cursor: pointer;
}

/*.kebab-menu span:nth-child(4){
    top: 175px;
    left: 35%;
    transition: .6s;
}
.kebab-menu span:nth-child(5){
    top: 175px;
    left: 35%;
    transition: .6s;
}

.kebab-menu:hover span:nth-child(1){
    transform: translate(70px,15px);
}
.kebab-menu:hover span:nth-child(2){
    transform: translate(-70px,15px);
}
.kebab-menu:hover span:nth-child(4){
    transform: translate(-70px,-15px);
}
.kebab-menu:hover span:nth-child(5){
    transform: translate(70px,-15px);
}*/