:root {
	--main-color: #424b5a;
	--secondary-color: #a1aeb7;
	--third-color: #255f8e;
	--fourth-color: #f2f5f7;
}

.fc-h-event {
	background-color: var(--third-color) !important;
	border: none !important;
	margin-top: 1px !important;
	margin-bottom: 1px !important;
}

.fc-h-event:hover {
	cursor: pointer;
	background-color: #235b88 !important;
}